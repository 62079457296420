import React from 'react';

const RaioX: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.38208 5H23.6181L20.6181 11H4.38208L7.38208 5ZM8.61815 7L7.61815 9H19.3821L20.3821 7H8.61815Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38208 13H19.6181L16.6181 19H0.38208L3.38208 13ZM4.61815 15L3.61815 17H15.3821L16.3821 15H4.61815Z"
      />
    </svg>
  );
};

export default RaioX;
